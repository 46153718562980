

  .navbar {
    width: 100%;
    height: 6rem;
    background: #fafafa;
    position: fixed;
    z-index: 1000;
    box-shadow: 2px 2px 3px 2px rgba(73, 73, 73, 0.75);
  }
  
  .links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    z-index: 10000;
  }
  
  .navbar a {
    color: #000;
    text-decoration: none;
    margin: 20px;
    font-size: 25px;
  }
  
  .toggleButton {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .toggleButton svg {
    font-size: 50px;
  }
  .toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: #000;
    cursor: pointer;
  }
  
  #open {
    height: 100vh;
    z-index: 1000000;
    overflow: hidden;
  }
  
  #open .links {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
  }
  
  #open a {
    width: 100%;
    text-align: center;
  }
  
  @media only screen and (max-width: 900px) {
    .navbar a {
      width: 70px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .toggleButton {
      display: flex;
    }
    #close a {
      display: none;
    }
  }
  
  @media only screen and (min-width: 600px) {
    .toggleButton {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }
  
    #open {
      height: 100px;
    }
  
    #open .links {
      flex-direction: row;
    }
  }