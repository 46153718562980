.perfil {
  background-color: #45c07d;
  width: 100%;
  padding: 1rem 0;
  color: #fafafa;
}

.contenido-perfil {
  display: flex;
  /*  align-items: center; */
  flex-direction: row;
  justify-content: space-between;
  max-width: 60%;
  margin: 0 auto;
  padding: 1rem 0;
}

.foto {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  width: 50%;
}
.texto {
  width: 50%;
  text-align: start;
  text-decoration: none;
  margin-top: 2rem;
}

/* Ajuste para pantallas pequeñas (mobile) */
@media (max-width: 600px) {
  .contenido-perfil {
    flex-direction: column;
    justify-content: center;
    max-width: 100%;
  }
  .texto {
    width: 96%;
    text-align: start;
  }
  .foto {
    margin-bottom: 2rem;
  }
  .parrafo {
    display: "flex" !important;
    align-items: center;
  }
}
